import axios from "axios";
import { convertToUSFormatString } from "../../utils";
import { getBaseUrl } from "./BaseService";

export function requestValidateToken(token, successCallback, errorCallBack) {
  var bodyFormData = new FormData();
  bodyFormData.append("token", token);
  return axios
    .post(getBaseUrl() + "/auth/validateToken", bodyFormData)
    .then((res) => successCallback())
    .catch((err) => errorCallBack());
}

export function getUsersListStatistics({
  page,
  size,
  dateFrom,
  dateTo,
  environment,
  candleTimeFrames,
  symbols,
}) {
  let path = `/report/usersListStatistics?dateFrom=${convertToUSFormatString(
    dateFrom
  )}&dateTo=${convertToUSFormatString(
    dateTo
  )}&page=${page}&size=${size}&environment=${environment}`;

  
  if (candleTimeFrames && candleTimeFrames.length > 0) {
    path += `&candleTimeFrames=${candleTimeFrames}`;
  }

  if (symbols && symbols.length > 0) {
    path += `&symbols=${symbols}`;
  }

  var finalUrl = getBaseUrl() + path;
  return axios.get(finalUrl);
}

export function getActiveUsersListStatistics({
  page,
  size,
  dateFrom,
  dateTo,
  environment,
}) {
  var finalUrl =
    getBaseUrl() +
    `/report/activeUsersListStatistics?dateFrom=${convertToUSFormatString(
      dateFrom
    )}&dateTo=${convertToUSFormatString(
      dateTo
    )}&page=${page}&size=${size}&environment=${environment}`;
  return axios.get(finalUrl);
}

export function getGeneralStatistics({
  dateFrom,
  dateTo,
  environment,
  candleTimeFrames,
  symbols,
}) {
  let path = `/report/generalStatistics?dateFrom=${convertToUSFormatString(
    dateFrom
  )}&dateTo=${convertToUSFormatString(
    dateTo
  )}&environment=${environment}&size=10000`;

  if (candleTimeFrames && candleTimeFrames.length > 0) {
    path += `&candleTimeFrames=${candleTimeFrames}`;
  }

  if (symbols && symbols.length > 0) {
    path += `&symbols=${symbols}`;
  }

  var finalUrl = getBaseUrl() + path;
  return axios.get(finalUrl);
}

export function getSingleUserSummarys({ accountId, dateFrom, dateTo }) {
  var finalUrl =
    getBaseUrl() +
    `/report/singleUserSummary?accountId=${accountId}&dateFrom=${convertToUSFormatString(
      dateFrom
    )}&dateTo=${convertToUSFormatString(dateTo)}`;
  return axios.get(finalUrl);
}

export function getSingleUserSummaryByReferralId({
  referralId,
  dateFrom,
  dateTo,
}) {
  var finalUrl =
    getBaseUrl() +
    `/report/singleUserSummaryByReferralId?referralId=${referralId}&dateFrom=${convertToUSFormatString(
      dateFrom
    )}&dateTo=${convertToUSFormatString(dateTo)}`;
  return axios.get(finalUrl);
}
export function getPlataformBalase({ page, size }) {
  var finalUrl =
    getBaseUrl() +
    `/report/platformTransactions?page=${page}&size=${size}`;
  return axios.get(finalUrl);
}

export function getStatisticsByPartner({ dateFrom, dateTo, page, size, sortAttributeName, sortMode }) {
  var finalUrl =
    getBaseUrl() +
    `/report/statistics/byPartner?dateFrom=${convertToUSFormatString(
      dateFrom
    )}&dateTo=${convertToUSFormatString(dateTo)}`;
    if (page) {
      finalUrl += `&page=${page}`;
    }
    if (size) {
      finalUrl += `&size=${size}`;
    }
  return axios.get(finalUrl);
}
