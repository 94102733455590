import { useCallback, useEffect, useState } from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { BarChart, Card, TabList, Tab, TabGroup, TabPanels, TabPanel } from "@tremor/react";
import csvDownload from "json-to-csv-export";
import TableStatisticsByPartner from "../../components/Tables/TableStatisticsByPartner";

import { intervalData } from "../../utils/constants";
import FiltersByInterval from "../dashboard/FiltersByInterval";
import { getStatisticsByPartner } from "../services/ReportService";

const colors = ["blue", "orange"];

export default function Partners() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(true);

  const [statistics, setStatistics] = useState([]);
  const [dateFrom, setDateFrom] = useState(intervalData["30d"].startDate);
  const [dateTo, setDateTo] = useState(new Date(Date.now()));
  const [interval, setInterval] = useState("30d");
  const [page, setPage] = useState(0);
  const [metadata, setMetadata] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(event);
    handlePartnerStatisticsRequest(event);
  };
  
  const handlePartnerStatisticsRequest = useCallback(async (page) => {
    try {
      setLoading(true);
      const statsRes = await getStatisticsByPartner({ dateFrom, dateTo, page, size: 100 });

      setStatistics(statsRes.data);
      setPage(statsRes.data.page);
      setMetadata({totalElements: statsRes.data.totalElements});

      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar estatísticas:", error);
    }
  }, [dateFrom, dateTo]);


  const setRangeInterval = useCallback(
    (interval) => {
      if (intervalData[interval]) {
        setDateFrom(intervalData[interval].startDate);
        setDateTo(new Date(Date.now()));
      }
    },
    [setDateFrom, setDateTo]
  );

  useEffect(() => {
    handlePartnerStatisticsRequest(0);
  }, [dateFrom, handlePartnerStatisticsRequest]);

  

  const requestCsvPartnerStatisticsDonwload = () => {
    const dateFrom = intervalData[interval === "custom" ? "all" : interval].startDate;

    const dateTo = new Date(Date.now());
    getStatisticsByPartner({ dateFrom, dateTo, page, size: 100 })
      .then((it) => {
        const { data } = it.data;
        const dataToConvert = {
          data,
          filename: `Partner Statistics | ${dateFrom} - ${dateTo}.csv`,
        };

        
        csvDownload(dataToConvert);
      })
      .catch((error) => {
        console.error("Erro ao gerar CSV:", error);
      });
  };

  return (
    <>
      <Stack
        sx={{ mb: 3 }}
        direction={isMobile ? "column" : "row"}
        spacing={2}
        justifyContent={"flex-end"}
      >
        <FiltersByInterval
          interval={interval}
          setInterval={setInterval}
          setRangeInterval={setRangeInterval}
          disableAllPeriod
          customDateFrom={dateFrom}
          customDateTo={dateTo}
          onChangeCustomDate={(e) => {
            setDateFrom(e.dateFrom);
            setDateTo(e.dateTo);
          }}
        />
      </Stack>

      <Card className="p-0">
        <TabGroup>
          <TabList>
            <Tab className="p-4 sm:p-6 text-left">
              <p className="text-sm sm:text-base">Saldo/Saques</p>
            </Tab>
            <Tab className="p-4 sm:p-6 text-left">
              <p className="text-sm sm:text-base">Volume de Saques/Lucro</p>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel className="p-6">
              <BarChart
                data={statistics.data?.map((d) => ({
                  "ID do Parceiro": d.partnerId,
                  Saldo: d.balance,
                  Saques: d.withdrawals,
                }))}
                index="ID do Parceiro"
                categories={["Saldo", "Saques"]}
                colors={colors}
                className="mt-6"
                showAnimation={true}
              />
            </TabPanel>
            <TabPanel className="p-6">
              <BarChart
                data={statistics.data?.map((d) => ({
                  "ID do Parceiro": d.partnerId,
                  "Volume de Saques": d.withdrawalsVolume,
                  Lucro: d.revenueShare,
                }))}
                index="ID do Parceiro"
                categories={["Volume de Saques", "Lucro"]}
                colors={colors}
                className="mt-6"
                showAnimation={true}
              />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </Card>

      <Box sx={{ mt: 4 }}>
        <TableStatisticsByPartner
          rows={statistics}
          loading={loading}
          rowsPerPage={metadata.totalElements}
          onDownloadCvs={requestCsvPartnerStatisticsDonwload}
          page={page}
          onPageChange={handleChangePage}
          metadata={metadata}
        />
      </Box>
    </>
  );
}