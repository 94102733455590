import { useCallback, useEffect, useState } from "react";
import TableCustomLayout from "./TableCustomLayout";
import TableHeadCustom from "./TableHeadCustom";
import { TableBody, TableCell, TableRow } from "@mui/material";
import ReactLoading from "react-loading";
import { getComparator, stableSort } from "../../utils/stableSort";
import TableCellCustom from "./TableCellCustom";
import TableRowCustom from "./TableRowCustom";

const DEFAULT_ORDER = "desc";
const DEFAULT_ORDER_BY = "balance";
const DEFAULT_ROWS_PER_PAGE = 100;

const headCells = [
  {
    id: "partnerId",
    numeric: false,
    disablePadding: false,
    label: "ID do Parceiro",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "balance",
    numeric: true,
    disablePadding: false,
    label: "Saldo",
  },
  {
    id: "withdrawals",
    numeric: true,
    disablePadding: false,
    label: "Saques",
  },
  {
    id: "withdrawalsVolume",
    numeric: true,
    disablePadding: false,
    label: "Volume de Saques",
  },
  {
    id: "revenueShare",
    numeric: true,
    disablePadding: false,
    label: "Lucro",
  },
];

let visibleRows = [];

export default function TableStatisticsByPartner(props) {
  const { onDownloadCvs, metadata, page, rowsPerPage, onPageChange, rows } = props;
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);

  useEffect(() => {
    if (Array.isArray(rows.data)) {
      let rowsOnMount = stableSort(rows.data, getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY));
      rowsOnMount = rowsOnMount.slice(0 * DEFAULT_ROWS_PER_PAGE, 0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE);
      visibleRows = rowsOnMount;
    } else {
      console.error("Erro no useEffect: 'rows.data' não é um array.", rows.data);
      visibleRows = [];
    }
  }, [rows.data]);

  const handleRequestSort = useCallback((event, newOrderBy) => {
    const isDesc = orderBy === newOrderBy && order === "desc";
    const toggledOrder = isDesc ? "asc" : "desc";
    setOrder(toggledOrder);
    setOrderBy(newOrderBy);

    if (Array.isArray(rows.data)) {
      const sortedRows = stableSort(rows.data, getComparator(toggledOrder, newOrderBy));
      const updatedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      visibleRows = updatedRows;
    } else {
      console.error("Erro no handleRequestSort: 'rows.data' não é um array.", rows.data);
    }
  }, [orderBy, order, page, rowsPerPage, rows.data]);

  return (
    <TableCustomLayout
      title="Estatísticas por Parceiro"
      data={visibleRows}
      onDownloadCvs={onDownloadCvs}
      metadata={metadata}
      page={page}
      rowsPerPage={rowsPerPage}
      onPageChange={onPageChange}
    >
      <TableHeadCustom order={order} orderBy={orderBy} headCells={headCells} onRequestSort={handleRequestSort} align="center" />
      <TableBody>
        {Array.isArray(visibleRows) && visibleRows.length > 0 && !props.loading ? (
          visibleRows.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRowCustom tabIndex={-1} key={row.index} sx={{ cursor: "pointer" }}>
                <TableCellCustom align="center" component="th" id={labelId} scope="row">
                  {row.partnerId}
                </TableCellCustom>
                <TableCellCustom align="center">{row.email}</TableCellCustom>
                <TableCellCustom align="center">{row.balance}</TableCellCustom>
                <TableCellCustom align="center">{row.withdrawals}</TableCellCustom>
                <TableCellCustom align="center">$ {row.withdrawalsVolume}</TableCellCustom>
                <TableCellCustom align="center">$ {row.revenueShare}</TableCellCustom>
              </TableRowCustom>
            );
          })
        ) : (
          <>
            {props.loading && (
              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={6}>
                  <ReactLoading type={"cylon"} color="#ab47bc" height={100} width={100} />
                </TableCell>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
              </TableRow>
            )}
            {!props.loading && visibleRows && visibleRows.length === 0 && (
              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell align="start" rowSpan={6}>Nenhum item.</TableCell>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
              </TableRow>
            )}
          </>
        )}
      </TableBody>
    </TableCustomLayout>
  );
}